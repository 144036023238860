<template>
    <div style="padding-top: 200px;"></div>
    <div class="auth-page-content overflow-hidden p-0">
        <div class="container  mb-5">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-8">
                    <div class="text-center">
                        <img src="/assets/images/error400-cover.png" alt="error img" class="img-fluid">
                        <div class="mt-3">
                            <h3 class="text-uppercase">Maaf, Halaman Tidak Ditemukan 😭</h3>
                            <p class="text-muted mb-4">Halaman yang anda maksud tidak tersedia!</p>
                            <button @click="backToHome()" class="btn btn-success"><i class="mdi mdi-home me-1"></i>Back
                                to
                                home</button>
                        </div>
                    </div>
                </div><!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
</template>

<script>
export default {
    created() {

    },
    methods: {
        backToHome() {
            let paths = this.$route.path.split("/");
            if (paths[1] == 'admin') {
                this.$router.push({
                    name: "home",
                    params: {
                        reload: true,
                    },
                });
            } else if (paths[1] == 'user') {
                this.$router.push({
                    name: "user.home",
                    params: {
                        reload: true,
                    },
                });
            } else {
                this.$router.push({
                    name: "user.home",
                    params: {
                        reload: true,
                    },
                });
            }
        }
    }
}
</script>

<style>
</style>